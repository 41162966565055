import React from 'react'

const Heading = (props) => {
  const fontSize = props.responsive
    ? 'text-2xl md:text-6xl'
    : 'md:text-6xl text-5xl'
  return (
    <h1
      className={`${fontSize} font-bold leading-tight ${props.className || ''}`}
    >
      {props.children}
    </h1>
  )
}

const H2 = (props) => {
  const fontSize = props.responsive ? 'text-2xl md:text-5xl' : 'text-5xl'
  return (
    <h2
      className={`${fontSize} font-bold font-roobert leading-tight ${
        props.className || ''
      }`}
    >
      {props.children}
    </h2>
  )
}

const H2Light = (props) => {
  const fontSize = props.responsive ? 'text-2xl md:text-5xl' : 'text-5xl'
  return (
    <h2
      className={`${fontSize} font-roobert leading-tight ${
        props.className || ''
      }`}
    >
      {props.children}
    </h2>
  )
}

const SubTitle = (props) => {
  const fontSize = props.responsive ? 'text-xl md:text-3xl' : 'text-3xl'

  return (
    <h3
      className={`${fontSize} font-bold font-roobert leading-tight ${
        props.className || ''
      }`}
    >
      {props.children}
    </h3>
  )
}

const Paragraph = (props) => (
  <p className={`font-roobert ${props.className || ''}`}>{props.children}</p>
)

const SmallHeading = (props) => (
  <h5 className={`uppercase text-xs tracking-widest ${props.className || ''}`}>
    {props.children}
  </h5>
)

const Link = (props) => (
  <a
    className={`text-[#2673F7] ${props.className || ''}`}
    href={props.href}
    target="_blank"
    rel="noreferrer noopener"
  >
    {props.children}
  </a>
)

export default {
  Heading,
  H2,
  H2Light,
  SubTitle,
  SmallHeading,
  Paragraph,
  Link,
}
