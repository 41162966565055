import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'

import calendar from '../../static/assets/img/calendar.svg'
import Typography from '../components/ui/typography'
import { NavWithLogoOnly } from '../components/ui/navigation/navigation'
import Container from '../components/ui/container'

import '../pages/base.css'

const { Paragraph, Link } = Typography

const options = {
  renderNode: {
    [BLOCKS.OL_LIST]: (node, children) => {
      return (
        <ol className="my-5 ml-10">
          {children.map((child, idx) => (
            <li className="list-decimal" key={idx}>
              {child}
            </li>
          ))}
        </ol>
      )
    },
    [BLOCKS.UL_LIST]: (node, children) => (
      <ul className="my-5 ml-10">
        {children.map((child, idx) => (
          <li className="list-disc" key={idx}>
            {child}
          </li>
        ))}
      </ul>
    ),
    [BLOCKS.LIST_ITEM]: (node, children) => children,
    [BLOCKS.HEADING_6]: (node, children) => {
      return <h6 className="my-3">{children}</h6>
    },
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return (
        <Paragraph className="mb-3 ml-5">
          {children}
        </Paragraph>
      )
    },
    [BLOCKS.HR]: () => {
      return (
        <hr className="my-6 border-darkGray"/>
      )
    },
    [INLINES.HYPERLINK]: (node, children) => {
      return (
        <Link href={node.data.uri} className="break-all">
          {children}
        </Link>
      )
    },
  },
}

// Prevent Gatsby SSR build errors
const isBrowser = typeof window !== 'undefined'

const TermsAndConditionsTemplate = (props) => {
  const data = get(props, 'data.contentfulTermsAndConditions')

  return (
    <div className="bg-white">
      <NavWithLogoOnly />
      <Container className="max-w-screen-md">
        <h2 className="text-3xl mb-8">{data.title}</h2>
        <div>
          {renderRichText(data.body, options)}
        </div>
        <div className="flex gap-2 ml-5">
          <img src={calendar} alt="calendar" className="h-18 w-18" />
          <p className="font-roobert font-normal text-md">Last update: {data.publishDate}</p>
        </div>
      </Container>
    </div>
  )
}

export default TermsAndConditionsTemplate

export const pageQuery = graphql`
  query TermsAndConditionsBySlug($slug: String!) {
    contentfulTermsAndConditions(slug: { eq: $slug }) {
      title
      slug
      publishDate(formatString: "MMMM Do, YYYY")
      body {
        raw
      }
    }
  }
`
